import React, { Component } from 'react';
import './App.scss';
import { Router, Route, Switch } from 'react-router-dom';
import store from './store/store';
import { createBrowserHistory } from 'history';
// Redux init
import { Provider } from 'react-redux';
import routes from './routes';
import PrivateRoute from './components/PrivateRoutes';
import { setLoginToken, logout } from './services/authService';
import localdataService from './services/localdataService';
import { initAnalytics } from './services/analyticsService';

initAnalytics();
const user = localdataService.getCurrentUser();
if (user) {
  /**
   * Set auth token header auth
   */
  const token = user.access_token;
  if (token) {
    store.dispatch(setLoginToken(user, false));
  } else {
    /**
     * Logout user
     */
    store.dispatch(logout());
  }
}
// Add history in Google Analytics
const history = createBrowserHistory();
class App extends Component {
  render() {
    const routeComponents = routes.map((r, i) => {
      if (r.private) {
        return <PrivateRoute key={i} {...r} />;
      } else {
        return <Route key={i} {...r} />;
      }
    });
    return (
      <Provider store={store}>
        <Router history={history}>
          <>
            <Switch>{routeComponents}</Switch>
          </>
        </Router>
      </Provider>
    );
  }
}

export default App;
