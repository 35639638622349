import * as actions from "../../actions/types";


const initialState = {
    resError: '',
    resSuccess: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_RES_SUCCESS_MSG:
            return {
                ...state,
                resSuccess: action.payload
            };
        case actions.SET_RES_ERROR_MSG:
            return {
                ...state,
                resError: action.payload
            };
        case actions.CLEAR_RES_MSG:
            return initialState;
        default:
            return state;
    }
}
