import React, { Component } from "react";
import "./Loading.scss";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="loader-block">
        <div className="loader-inner">
          <div className="loader"></div>
        </div>
      </div>
    );
  }
}

export default Loading;
