import * as actions from "./types";

/**
 * @desc Set pricing loader
 */
export const setPricingLoader = (payload) => {
    return {
        type: actions.SET_PRICING_LOADER,
        payload: payload
    };
};

/**
 * @desc Set pricing track
 */
export const setPricingTrack = (payload) => {
    return {
        type: actions.SET_PRICING_TRACK,
        payload: payload
    };
};

/**
 * @desc Set selected buy tracks
 */
export const setSelectedBuyTracks = (payload) => {
    return {
        type: actions.SET_SELECTED_BUY_TRACKS,
        payload: payload
    };
};

/**
 * @desc Set selected plan
 */
export const setSelectedPlan = (payload) => {
    return {
        type: actions.SET_SELECTED_PLAN,
        payload: payload
    }
}

/**
 * @desc Set transaction list
 */
export const setTransactionList = (payload) => {
    return {
        type: actions.SET_TRANSACTIONS_LIST,
        payload: payload
    }
}

/**
 * @desc Set payment types list
 */
export const setPaymentTypesList = (payload) => {
    return {
        type: actions.SET_PAYMENT_TYPES_LIST,
        payload: payload
    }
}