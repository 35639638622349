import React, { Component } from 'react';
import classNames from 'classnames';

export class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: ''
    };
  }

  componentDidMount() {
    const { selectedValue } = this.props;
    this.setValue(selectedValue);
  }

  setValue = (selectedValue) => {
    this.setState({ selectedValue });
  };

  onChange = (e) => {
    const { value } = e.target;
    if (value) {
      this.setValue(value);
      if (this.props.onChange) this.props.onChange(value);
    }
  };
  render() {
    const { selectedValue } = this.state;
    const { options } = this.props;
    return (
      <select className="language-box"
        value={selectedValue}
        onChange={(e) => {
          this.onChange(e);
        }}>
        {options &&
          options.length > 0 &&
          options.map((item) => (
            <option
              key={String(item.id)}
              value={item.id}
              className={classNames('', {
                selected: selectedValue === item.id
              })}>
              {item.value}
            </option>
          ))}
      </select>
    );
  }
}

export default DropDown;
