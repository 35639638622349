
const pricingPlans = [
    { id: "individual_track", name: "Individual Track(s)", displayName: "Basic Plan", price: 9, type: 1 },
    { id: "unlimited_tracks", name: "Unlimited Tracks", displayName: "Pro Plan", price: 19, type: 2 },
    { id: "particular_track_DMR", name: "DelMar Tracks", displayName: "Unlimited plan - Del Mar", price: 179, type: 3 },
    { id: "particular_track_SRT", name: "Saratoga Tracks", displayName: "Unlimited plan - Saratoga", price: 275, type: 4 },
    { id: "churchill_track", name: "Churchill Track", displayName: "Derby or Oaks Day - Churchill Downs", price: 25, type: 5 },
    { id: "particular_track_CHR", name: "Churchill Tracks", displayName: "Derby & Oaks Bundle - Churchill Downs", price: 39, type: 6 },
    { id: "pimlico_track", name: "Pimlico Track", displayName: "Pimlico", price: 25, type: 7 },
    { id: "breeders_track", name: "Breeders' Cup Track", displayName: "Breeders' Cup", price: 25, type: 8 },
]

export default pricingPlans;