import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images/index';
import OnboardingStep1 from './OnboardingStep1';
import OnboardingStep2 from './OnboardingStep2';
import { setOnboardingDetails } from '../../services/onboardingService';
import { setNewUser, trackActivity } from './../../services/analyticsService';
import { getFirstAndLastName } from '../../global/Helpers';
import OnboardingStep3 from './OnboardingStep3';
import { updateUserProfile } from '../../services/authService';

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 1,
      user_type: undefined,
      interested_racetracks: undefined
    };
  }

  onNext = async (args) => {
    if (!args) return;

    let { stepIndex, user_type, interested_racetracks } = this.state;
    let properties;
    if (stepIndex === 1) {
      user_type = args;
      properties = {
        persona: args
      };
    } else if (stepIndex === 2) {
      interested_racetracks = args;
      properties = {
        track_preferences: args
      };
    }
    trackActivity(`onboarding - step ${stepIndex}:  submitted`, properties);
    this.setState({ user_type, interested_racetracks }, async() => {
      if (stepIndex === 2) await this.setOnboarding();
    });
    this.setNextStep();
  };

  setOnboarding = async () => {
    const { user_type, interested_racetracks } = this.state;

    const onboardingObj = {
      user_type: user_type,
      interested_racetracks: interested_racetracks
    };

    const result = await this.props.setOnboardingDetails(onboardingObj);
    if (result) {
      const { user } = this.props.auth;
      const nameObj = getFirstAndLastName(user.displayName);
      const { firstname, lastname } = nameObj || {};
      const analyticsObj = {
        name: user.displayName,
        email: user.email,
        firstname: firstname || '',
        lastname: lastname || '',
        track_preferences: interested_racetracks.join(', '),
        persona: user_type.join(', ')
      };
      setNewUser(analyticsObj);
    }
  };

  setNextStep = () => {
    let { stepIndex } = this.state;
    stepIndex = this.state.stepIndex + 1;
    this.setState({ stepIndex });
  };

  onGetStarted = async (language) => {
    if (!language) return;
    trackActivity('onboarding - step 3: submitted', { language_preference: language });
    const result = await this.props.updateUserProfile({ language });
    if (result) {
      this.props.history.push('/dashboard');
    }
  };

  render() {
    const { stepIndex } = this.state;
    return (
      <>
        <div className='login-body'>
          <div className='login-wrapper'>
            <div className='login-rightbar-head mt14'>
              <a className='w-inline-block'>
                <img src={images['logo']} alt='' />
              </a>
            </div>
            {stepIndex === 1 && <OnboardingStep1 onNext={this.onNext} />}
            {stepIndex === 2 && <OnboardingStep2 onNext={this.onNext} />}
            {stepIndex === 3 && <OnboardingStep3 onGetStarted={this.onGetStarted} />}
          </div>
        </div>
      </>
    );
  }
}

Onboarding.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setOnboardingDetails,
  updateUserProfile
})(Onboarding);
