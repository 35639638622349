import { API_URL, SEGMENT_KEY, STRIPE_PUBLIC_KEY, API_URL_STAGING } from './constant';
/**
 * @desc Environment Variables
 */
export const REACT_APP_ENV = process.env.REACT_APP_ENV;
let apiUrl = API_URL;
if (REACT_APP_ENV === 'staging') apiUrl = API_URL_STAGING;

export const REACT_APP_APIURL = process.env.REACT_APP_APIURL || apiUrl;
export const REACT_APP_SEGMENT_KEY = SEGMENT_KEY || 'a5SGCnPIOoAe0ppWmFGpqw90c63rLpI9';
export const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || STRIPE_PUBLIC_KEY;
