import * as actions from '../../actions/types';
import { isEmpty } from '../../global/Helpers';

const initialState = {
  isAuthenticated: false,
  user: {},
  userProfile: {},
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_AUTH_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    default:
      return state;
  }
}
