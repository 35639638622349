const currentUserKey = 'user';

const getCurrentUser = () => {
  return getLocalData(currentUserKey);
};
const setCurrentUser = (user) => {
  setLocalData(currentUserKey, user);
};
const deleteCurrentUser = () => {
  removeLocalData(currentUserKey);
};

export const ClearAllKeys = async () => {
  removeLocalData(currentUserKey);
};

const setLocalData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getLocalData = (key) => {
  const value = localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
  return;
};

const removeLocalData = (key) => {
  localStorage.removeItem(key);
};

export default {
  getCurrentUser,
  setCurrentUser,
  deleteCurrentUser,
  setLocalData,
  getLocalData,
  removeLocalData,
  ClearAllKeys
};
