import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images';
import { getPricingTracks, chargePaymentOffSession, createPaymentIntent, getCustomerCardDetails } from '../../services/pricingService';
import Loading from '../../components/Loading/Loading';
import { setSelectedBuyTracks, setSelectedPlan, setPricingTrack } from '../../actions/pricingActions';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { setErrorMessage, clearResponseMessage } from '../../actions/messageActions';
import { initWebflowReady, getUrlParam } from '../../global/Helpers';
import PaymentModal from './PaymentModal';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../global/Environment';
import pricingPlans from '../../data/pricingPlans';
import { trackActivity } from '../../services/analyticsService';
import { logout } from '../../services/authService';

const stripe = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);
class PricingTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentModal: false
    };
  }

  componentDidMount() {
    let source = getUrlParam(this.props.location.search, 'planId');
    trackActivity('navigation: pricing track', source && { source: source });
    this.LoadData();
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  LoadData = async () => {
    this.props.setPricingTrack();
    let planId = getUrlParam(this.props.location.search, 'planId');
    const { selectedPlan } = this.props.pricing;
    if (!selectedPlan.id) this.setSelectedPlan(planId);
    this.props.setSelectedBuyTracks([]);
    const isAlltrack = selectedPlan.id === 'unlimited_tracks' || planId === 'unlimited_tracks';
    await this.props.getPricingTracks(isAlltrack, planId);
    initWebflowReady();
  };

  setSelectedPlan = (planId) => {
    if (!planId) return;
    const selectedPlan = pricingPlans.find((x) => x.id === planId);
    this.props.setSelectedPlan(selectedPlan);
  };

  addToCart = (item, index) => {
    let selectedBuyTracks = this.props.pricing.selectedBuyTracks;
    selectedBuyTracks.push({ id: index, ...item });
    trackActivity('pricing - track: add to cart', { track: String(item.track).toLowerCase(), date: item.formated_date });
    this.props.setSelectedBuyTracks(selectedBuyTracks);
  };

  removeFromCart = (item, id) => {
    let selectedBuyTracks = this.props.pricing.selectedBuyTracks;
    selectedBuyTracks = selectedBuyTracks.filter((x) => x.id !== id);
    trackActivity('pricing - track: remove from cart', { track: String(item.track).toLowerCase(), date: item.formated_date });
    this.props.setSelectedBuyTracks(selectedBuyTracks);
  };

  onCheckoutClick = () => {
    this.onOpenPaymentModal();
  };

  onOpenPaymentModal = () => {
    this.props.clearResponseMessage();
    this.setState({ isPaymentModal: true });
  };

  onClosePaymentModal = () => {
    this.setState({ isPaymentModal: false });
  };

  onDashboardClick = () => {
    this.props.history.push('/dashboard');
  }

  onTransactionsClick = () => {
    this.props.history.push('/transactions');
  };

  onPurchaseClick = () => {
    this.props.history.push('/pricing');
  };

  onPaymentTypesClick = () => {
    this.props.history.push('/manage-payments');
  };

  onProfileClick = () => {
    this.props.history.push('/profile');
  }

  onGlossaryClick = () => {
    this.props.history.push('/glossary');
  }

  onLogOut = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    const { loading, pricingTrack, selectedBuyTracks, selectedPlan } = this.props.pricing;
    const { isPaymentModal } = this.state;

    const amount = Number(selectedPlan.price) * Number(selectedBuyTracks.length);

    if (loading && !pricingTrack) return <Loading />;
    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo']} alt='' />
              </a>
              {/* <div className='nav-btn'>
                <button type='button' className='btn-primary' onClick={this.navToDashboard}>
                  dashboard
                </button>
              </div> */}
              <div className='profile-block'>
                <div className='w-dropdown'>
                  <div className='dropdown-toggle w-dropdown-toggle'>
                    {user && user.photoURL && (
                      <div className='profile-img'>
                        <img src={user.photoURL} width='36' height='36' alt='' />
                      </div>
                    )}
                    <div className='profile-arrow w-icon-dropdown-toggle'></div>
                    <div className='profile-name'>{user.displayName}</div>
                  </div>
                  <nav className='dropdown-list w-dropdown-list'>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onDashboardClick}>
                      Dashboard
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onPurchaseClick}>
                      Purchase
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onTransactionsClick}>
                      Transactions
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onPaymentTypesClick}>
                      Manage Payments
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onProfileClick}>
                      Profile
                    </a>
                    <a className='dropdown-link w-dropdown-link' href="https://www.tripnotepros.com/glossary" target="_blank">
                      Glossary
                    </a>
                    <a className='dropdown-link w-dropdown-link' href="mailto:benny@tripnotepros.com" target="_blank">
                      Contact Us
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onLogOut}>
                      Logout
                    </a>
                  </nav>
                </div>
              </div>
              {/* <nav className='nav-menu home w-nav-menu'>
                <div className='nav-btn'>
                  <a className='btn-primary' href='/dashboard'>
                    dashboard
                  </a>
                </div>
              </nav> */}
              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content normal'>
          <div className='container w-container'>
            <div className='pricing-head-block'>
              <h4 className='pricing-title'>Select Your Track :</h4>
            </div>
            <div className='track-wrapper'>
              <div className='track-left'>
                <ul className='track-list w-list-unstyled'>
                  {pricingTrack &&
                    pricingTrack.length > 0 &&
                    pricingTrack.map((item, index) => {
                      const isItemAdded = selectedBuyTracks && selectedBuyTracks.some((x) => x.id === index);
                      return (
                        <li className='track-item' key={String(index)}>
                          <div className='track-info'>
                            <h5 className='track-title'>{item.track}</h5>
                            <p className='race-text'></p>
                          </div>
                          <div className='track-time'>
                            <p className='race-text'>{item.formated_date}</p>
                            <p className='time-text'></p>
                          </div>
                          {!isItemAdded ? (
                            <div
                              className='track-btn'
                              onClick={() => {
                                this.addToCart(item, index);
                              }}>
                              <button type='button' className='btn-cart w-button'>
                                Add to Cart
                              </button>
                            </div>
                          ) : (
                              <div
                                className='track-btn'
                                onClick={() => {
                                  this.removeFromCart(item, index);
                                }}>
                                <button type='button' className='btn-cart remove w-button'>
                                  Remove
                              </button>
                              </div>
                            )}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className='track-right'>
                <div className='track-right-head'>
                  <h4 className='track-right-title'>Your Order :</h4>
                </div>
                <ul className='order-list w-list-unstyled'>
                  {selectedBuyTracks &&
                    selectedBuyTracks.length > 0 &&
                    selectedBuyTracks.map((item) => (
                      <li className='order-item' key={String(item.id)}>
                        <div className='track-info'>
                          <h5 className='track-title'>{item.track}</h5>
                          <p className='race-text'></p>
                        </div>
                        <div className='track-time'>
                          <p className='race-text'>{item.formated_date}</p>
                          <p className='time-text'></p>
                        </div>
                      </li>
                    ))}
                </ul>
                <div className='order-item amount'>
                  <div className='amount-block'>
                    <h5 className='amount-text'>Total Amount :</h5>
                    <p className='amount-text num'>$ {amount}</p>
                  </div>
                  <button type='button' className='btn-square f-wth w-button' onClick={this.onCheckoutClick}>
                    Checkout
                  </button>
                </div>
              </div>
            </div>
            <div className='track-right mobile'>
              <div className='order-item normal'>
                <div className='amount-block'>
                  <h5 className='amount-text'>Total Amount :</h5>
                  <p className='amount-text num'>$ {amount}</p>
                </div>
                <button type='button' className='btn-square f-wth w-button' onClick={this.onCheckoutClick}>
                  Checkout
                </button>
              </div>
            </div>
          </div>
          {/* Modal */}

          {isPaymentModal && (
            <Elements stripe={stripe}>
              <PaymentModal
                selectedBuyTracks={selectedBuyTracks}
                selectedPlan={selectedPlan}
                amount={amount}
                loading={loading}
                {...this.props}
                onClosePaymentModal={this.onClosePaymentModal}
              />
            </Elements>
          )}
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}

PricingTrack.propTypes = {
  message: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  pricing: state.pricing,
  auth: state.auth,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  getPricingTracks,
  setSelectedBuyTracks,
  setErrorMessage,
  setSelectedPlan,
  clearResponseMessage,
  createPaymentIntent,
  chargePaymentOffSession,
  getCustomerCardDetails,
  setPricingTrack,
  logout
})(PricingTrack);
