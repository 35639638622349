import * as actions from "./types";

/**
 * @desc Set auth loader
 */
export const setAuthLoader = (payload) => {
    return {
        type: actions.SET_AUTH_LOADER,
        payload: payload
    };
};

// Set logged in user
export const setCurrentUser = (payload) => {
    return {
        type: actions.SET_CURRENT_USER,
        payload: payload
    };
};

// Set user Profile data
export const setUserProfile = (payload) => {
    return {
        type: actions.SET_USER_PROFILE,
        payload: payload
    }
}
