import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images';
import { Elements } from '@stripe/react-stripe-js';
import { setErrorMessage, clearResponseMessage } from '../../actions/messageActions';
import { initWebflowReady } from '../../global/Helpers';
import PaymentModal from './PaymentModal';
import { setSelectedBuyTracks, setSelectedPlan } from '../../actions/pricingActions';
import { chargePaymentOffSession, createPaymentIntent, getCustomerCardDetails } from '../../services/pricingService';
import { loadStripe } from '@stripe/stripe-js';
import pricingPlans from '../../data/pricingPlans';
import { REACT_APP_STRIPE_PUBLIC_KEY } from '../../global/Environment';
import { trackActivity } from '../../services/analyticsService';
import { logout } from '../../services/authService';

const stripe = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentModal: false
    };
  }

  componentDidMount() {
    initWebflowReady();
    trackActivity('navigation: pricing');
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onPickTrackClick = (planId) => {
    const { user } = this.props.auth;
    if (user && user.email) {
      this.setSelectedPlan(planId);
      this.props.history.push(`/pricing-track?planId=${planId}`);
    } else {
      this.props.history.push('/signup');
    }
  };

  setSelectedPlan = (planId) => {
    if (!planId) return;
    const selectedPlan = pricingPlans.find((x) => x.id === planId);
    this.props.setSelectedPlan(selectedPlan);
  };

  onParticularPlanClick = (planId) => {
    const { user } = this.props.auth;
    if (user && user.email) {
      this.setSelectedPlan(planId);
      this.onOpenPaymentModal();
    } else {
      this.props.history.push('/signup');
    }
  };

  onOpenPaymentModal = () => {
    this.props.clearResponseMessage();
    this.setState({ isPaymentModal: true });
  };

  onClosePaymentModal = () => {
    this.setState({ isPaymentModal: false });
  };

  onDashboardClick = () => {
    this.props.history.push('/dashboard');
  }

  onTransactionsClick = () => {
    this.props.history.push('/transactions');
  };

  onPaymentTypesClick = () => {
    this.props.history.push('/manage-payments');
  };

  onProfileClick = () => {
    this.props.history.push('/profile');
  }

  onGlossaryClick = () => {
    this.props.history.push('/glossary');
  }

  onLogOut = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    const { loading, selectedPlan, selectedBuyTracks } = this.props.pricing;
    const { isPaymentModal } = this.state;
    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo']} alt='' />
              </a>
              {/* <div className='nav-top-menubar'>
                <button type='button' className='nav-link w-nav-link ctm' onClick={this.navToDashboard}>
                  dashboard
                </button>
              </div> */}
              <div className='profile-block'>
                <div className='w-dropdown'>
                  <div className='dropdown-toggle w-dropdown-toggle'>
                    {user && user.photoURL && (
                      <div className='profile-img'>
                        <img src={user.photoURL} width='36' height='36' alt='' />
                      </div>
                    )}
                    <div className='profile-arrow w-icon-dropdown-toggle'></div>
                    <div className='profile-name'>{user.displayName}</div>
                  </div>
                  <nav className='dropdown-list w-dropdown-list'>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onDashboardClick}>
                      Dashboard
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onTransactionsClick}>
                      Transactions
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onPaymentTypesClick}>
                      Manage Payments
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onProfileClick}>
                      Profile
                    </a>
                    <a className='dropdown-link w-dropdown-link' href="https://www.tripnotepros.com/glossary" target="_blank">
                      Glossary
                    </a>
                    <a className='dropdown-link w-dropdown-link' href="mailto:benny@tripnotepros.com" target="_blank">
                      Contact Us
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onLogOut}>
                      Logout
                    </a>
                  </nav>
                </div>
              </div>
              {/* <nav className='nav-menu home w-nav-menu'>
                <div className='nav-btn'>
                  <a className='btn-primary' href='/dashboard'>
                    dashboard
                  </a>
                </div>
              </nav> */}
              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content'>
          <div className='pricing-block'>
            <div className='pricing-box-wp'>
              <div className='pricing-box'>
                <h5 className='plan-text'>Basic Plan</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>9
                </div>
                <p className='plan-p'>Per Track per day</p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onPickTrackClick('individual_track') }}>
                  Pick Your Track
                </button>
              </div>
              {/* <div className='pricing-box pro'>
                <h5 className='plan-text'>Pro Plan</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>19
                </div>
                <p className='plan-p lg'>
                  Unlimited Day Access
                  <br />
                  DMR, KEE & BEL.
                </p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onPickTrackClick('unlimited_tracks') }}>
                  Get Started
                </button>
              </div> */}
              {/* <div className='pricing-box pro'>
                <h5 className='plan-text'>Derby or Oaks Day - Churchill Downs</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>25
                </div>
                <p className='plan-p lg'>
                  Per Track per day
                </p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onPickTrackClick('churchill_track') }}>
                  Get Started
                </button>
              </div>
              <div className='pricing-box pro'>
                <h5 className='plan-text'>Derby & Oaks Bundle - Churchill Downs</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>39
                </div>
                <p className='plan-p lg'>
                  Both days
                </p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onParticularPlanClick('particular_track_CHR') }}>
                  Get Started
                </button>
              </div> */}
              {/* <div className='pricing-box pro'>
                <h5 className='plan-text'>Preakness Day</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>25
                </div>
                <p className='plan-p lg'>
                  Per Track per day
                </p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onPickTrackClick('pimlico_track') }}>
                  Get Started
                </button>
              </div> */}
              <div className='pricing-box pro'>
                <h5 className='plan-text'>Breeders&apos Cup</h5>
                <div className='pricing-text'>
                  <span className='doller-text'>$</span>25
                </div>
                <p className='plan-p lg'>
                  Per Track per day
                </p>
                <button type='button' className='btn-square w-button' onClick={() => { this.onPickTrackClick('breeders_track') }}>
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}

        {
          isPaymentModal && (
            <Elements stripe={stripe}>
              <PaymentModal
                selectedBuyTracks={selectedBuyTracks}
                selectedPlan={selectedPlan}
                amount={selectedPlan.price}
                loading={loading}
                {...this.props}
                onClosePaymentModal={this.onClosePaymentModal}
              />
            </Elements>
          )
        }
        {this.renderErrors()}
      </div >
    );
  }
}

Pricing.propTypes = {
  message: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  pricing: state.pricing,
  auth: state.auth,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setSelectedBuyTracks,
  setErrorMessage,
  setSelectedPlan,
  clearResponseMessage,
  createPaymentIntent,
  chargePaymentOffSession,
  getCustomerCardDetails,
  logout
})(Pricing);
