import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Loading from '../../components/Loading/Loading';
import images from '../../assets/images';
import { clearResponseMessage } from '../../actions/messageActions';
import { getUserProfile, updateUserProfile, logout } from '../../services/authService';
import languages from '../../data/languages';
import { DropDown } from '../../components/DropDown';
import { setUserProfile } from '../../actions/authActions';
import { initWebflowReady } from '../../global/Helpers';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.LoadData();
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  LoadData = async () => {
    await this.props.getUserProfile();
    initWebflowReady();
  };

  onDashboardClick = () => {
    this.props.history.push('/dashboard');
  };

  onPurchaseClick = () => {
    this.props.history.push('/pricing');
  };

  onPaymentTypesClick = () => {
    this.props.history.push('/manage-payments');
  };

  onTransactionsClick = () => {
    this.props.history.push('/transactions');
  };

  onGlossaryClick = () => {
    this.props.history.push('/glossary');
  };

  onLogOut = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  handlefirstNameInputChange = (e) => {
    const firstName = e.target.value;
    const { userProfile } = this.props.auth;
    if (userProfile) {
      userProfile.firstName = firstName;
    }
    this.props.setUserProfile(userProfile);
  };

  handlelastNameInputChange = (e) => {
    const lastName = e.target.value;
    const { userProfile } = this.props.auth;
    if (userProfile) {
      userProfile.lastName = lastName;
    }
    this.props.setUserProfile(userProfile);
  };

  setLanguage = (language) => {
    const { userProfile } = this.props.auth;
    if (userProfile) {
      userProfile.language = language;
    }
    this.props.setUserProfile(userProfile);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, language } = this.props.auth;
    const profileObj = {
      firstName: firstName,
      lastName: lastName,
      language: language
    };
    const result = await this.props.updateUserProfile(profileObj);
    if (result) {
      this.LoadData();
    }
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user, loading, userProfile } = this.props.auth;
    const { firstName, lastName, language } = userProfile;
    if (loading && (!userProfile || !userProfile.firstName)) return <Loading />;

    return (
      <div className='login-body'>
        <div className='navbar pricing w-nav'>
          <div className='container w-container'>
            <div className='nav-inner-home pricing'>
              <a className='brand-logo-home w-nav-brand'>
                <img src={images['logo']} alt='' />
              </a>
              <div className='profile-block'>
                <div className='w-dropdown'>
                  <div className='dropdown-toggle w-dropdown-toggle'>
                    {user && user.photoURL && (
                      <div className='profile-img'>
                        <img src={user.photoURL} width='36' height='36' alt='' />
                      </div>
                    )}
                    <div className='profile-arrow w-icon-dropdown-toggle'></div>
                    <div className='profile-name'>{user.displayName}</div>
                  </div>
                  <nav className='dropdown-list w-dropdown-list'>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onDashboardClick}>
                      Dashboard
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onPurchaseClick}>
                      Purchase
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onTransactionsClick}>
                      Transactions
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onPaymentTypesClick}>
                      Manage Payments
                    </a>
                    <a
                      className='dropdown-link w-dropdown-link'
                      href='https://www.tripnotepros.com/glossary'
                      target='_blank'>
                      Glossary
                    </a>
                    <a className='dropdown-link w-dropdown-link' href='mailto:benny@tripnotepros.com' target='_blank'>
                      Contact Us
                    </a>
                    <a className='dropdown-link w-dropdown-link' onClick={this.onLogOut}>
                      Logout
                    </a>
                  </nav>
                </div>
              </div>
              <div className='menu-button w-nav-button'>
                <div className='icon w-icon-nav-menu'></div>
                <div className='top-line'></div>
                <div className='middle-line'></div>
                <div className='bottom-line'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='pricing-content normal'>
          <div className='container w-container'>
            <div className='editprofile-wrapper'>
              <div className='pricing-head-block'>
                <h4 className='pricing-title'>Edit Profile</h4>
              </div>
              <div className='editprofile-block'>
                <form name='wf-form-Signup-Form' onSubmit={this.handleSubmit}>
                  <div className='form-row'>
                    <div className='form-col mb'>
                      <label htmlFor='First-Name' className='form-lable'>
                        First Name
                      </label>
                      <input
                        type='text'
                        className='form-input w-input'
                        maxLength='256'
                        name='firstName'
                        placeholder='John'
                        required=''
                        value={firstName}
                        onChange={this.handlefirstNameInputChange}
                      />
                    </div>
                    <div className='form-col mb'>
                      <label htmlFor='Last-Name' className='form-lable'>
                        Last Name
                      </label>
                      <input
                        type='text'
                        className='form-input w-input'
                        maxLength='256'
                        name='lastName'
                        placeholder='Doe'
                        required=''
                        value={lastName}
                        onChange={this.handlelastNameInputChange}
                      />
                    </div>
                    <div className='form-col mb'>
                      <label htmlFor='Last-Name' className='form-lable'>
                        Language
                      </label>
                      <DropDown
                        selectedValue={language}
                        options={languages}
                        onChange={this.setLanguage}
                      />
                    </div>
                  </div>
                  <div className='alert-box'>{this.renderErrors()}</div>
                  <div
                    className={classNames('form-btn-block', {
                      'mc-loading': loading === true
                    })}>
                    <button type='submit' className='btn-square pay w-button' disabled={loading}>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  message: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth
});

export default connect(mapStateToProps, {
  clearResponseMessage,
  getUserProfile,
  setUserProfile,
  updateUserProfile,
  logout
})(Profile);
