import * as actions from '../../actions/types';

const initialState = {
  raceData: {},
  selectedRace: {},
  selectedRaceHorses: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SET_SAMPLE_DASHBOARD_LOADER:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_SAMPLE_RACE_DATA:
      const sortedTripListRaceData = getSortedTripList(action.payload);
      return {
        ...state,
        raceData: sortedTripListRaceData
      };
    case actions.SET_SAMPLE_SELECTED_RACE:
      return {
        ...state,
        selectedRace: action.payload
      };
    case actions.SET_SAMPLE_SELECTED_RACE_HORSES:
      return {
        ...state,
        selectedRaceHorses: action.payload
      };
    default:
      return state;
  }
}

const getSortedTripList = (data) => {
  if (!data) return;

  if (data.trips) {
    data.trips = data.trips
      .map((item) => {
        return { ...item, number: Number(item.horse_number) };
      })
      .sort((a, b) => {
        var o1 = a.race.toLowerCase();
        var o2 = b.race.toLowerCase();

        var p1 = a.number;
        var p2 = b.number;

        if (o1 < o2) return -1;
        if (o1 > o2) return 1;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
      });
  }
  return data;
};
