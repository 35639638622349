import React, { Component } from 'react';
import classNames from 'classnames';

class EditPaymentTypeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expMonth: '',
            expYear: ''
        };
    }

    componentDidMount() {
        this.LoadData();
    }

    LoadData = () => {
        const { selectedPaymentType } = this.props;
        const { card } = selectedPaymentType || {};
        const { exp_month, exp_year } = card || {};
        this.setState({ expMonth: exp_month, expYear: exp_year });
    }

    handleExpMonthInputChange = (e) => {
        const expMonth = e.target.value;
        this.setState({ expMonth });
    };

    handleExpYearInputChange = (e) => {
        const expYear = e.target.value;
        this.setState({ expYear });
    };


    handleSubmit = async (e) => {
        e.preventDefault();
        const { selectedPaymentType } = this.props;
        const { expMonth, expYear } = this.state;
        const obj = {
            item: selectedPaymentType,
            expMonth,
            expYear
        };
        if (this.props.onEditSubmitted) this.props.onEditSubmitted(obj);
    }

    onCloseModal = () => {
        const { onCloseEditPaymentTypeModal } = this.props;
        if (onCloseEditPaymentTypeModal) onCloseEditPaymentTypeModal();
    };

    renderErrors = () => {
        const { resError } = this.props;
        if (resError) {
            return <div className='alert-message'>{resError}</div>;
        }
    };

    render() {
        const { loading, selectedPaymentType } = this.props;
        if (!selectedPaymentType) return null;
        const { expMonth, expYear } = this.state;
        return (
            <div className='payment-model-wrapper d-block'>
                <div className='payment-model-inner'>
                    <form name='wf-form-Signup-Form' onSubmit={this.handleSubmit}>
                        <div className='payment-model-block'>
                            <div id='card-element' className='payment-model-top'>
                                <div className='form-row'>
                                    <div className='form-col mb'>
                                        <label htmlFor='Exp-Month' className='form-lable'>
                                            Exp Month
                                        </label>
                                        <input
                                            type='text'
                                            className='form-input w-input'
                                            maxLength='256'
                                            name='expMonth'
                                            required=''
                                            placeholder='enter card exp month'
                                            value={expMonth}
                                            onChange={this.handleExpMonthInputChange}
                                        />
                                    </div>
                                    <div className='form-col'>
                                        <label htmlFor='Exp-Year' className='form-lable'>
                                            Exp Year
                                        </label>
                                        <input
                                            type='text'
                                            className='form-input w-input'
                                            maxLength='256'
                                            name='expYear'
                                            required=''
                                            placeholder='enter card exp year'
                                            value={expYear}
                                            onChange={this.handleExpYearInputChange}
                                        />
                                    </div>
                                </div>
                                <div className='alert-box'>{this.renderErrors()}</div>
                            </div>
                            <div className='payment-btn-block'>
                                <button
                                    type='button'
                                    className='btn-cancel'
                                    disabled={loading}
                                    onClick={this.onCloseModal}>
                                    Cancel
                                </button>
                                <div
                                    className={classNames('form-btn-block', {
                                        'mc-loading': loading === true
                                    })}>
                                    <button
                                        type='submit'
                                        className='btn-square pay w-button'
                                        disabled={loading}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditPaymentTypeModal;
