const images = {
  agent: require("./agent.svg"),
  bgimgP1080: require("./bgimg-p-1080.png"),
  bgimgP500: require("./bgimg-p-500.png"),
  bgimgP800: require("./bgimg-p-800.png"),
  bgimg: require("./bgimg.png"),
  bglightP1080: require("./bglight-p-1080.png"),
  bglightP1600: require("./bglight-p-1600.png"),
  bglightP500: require("./bglight-p-500.png"),
  bglightP800: require("./bglight-p-800.png"),
  bglight: require("./bglight.png"),
  california: require("./california.svg"),
  churchillP500: require("./churchill-p-500.png"),
  churchillP800: require("./churchill-p-800.png"),
  churchill: require("./churchill.png"),
  closebk: require("./closebk.svg"),
  currentdot: require("./currentdot.svg"),
  eyeonicon: require("./eyeonicon.svg"),
  facebook: require("./facebook.svg"),
  facebookicon: require("./facebookicon.svg"),
  favicon: require("./favicon.svg"),
  florida: require("./florida.svg"),
  footerPhotoP1080: require("./footer-photo-p-1080.png"),
  footerPhotoP1600: require("./footer-photo-p-1600.png"),
  footerPhotoP2000: require("./footer-photo-p-2000.png"),
  footerPhotoP2600: require("./footer-photo-p-2600.png"),
  footerPhotoP500: require("./footer-photo-p-500.png"),
  footerPhotoP800: require("./footer-photo-p-800.png"),
  footer: require("./footer-photo.png"),
  googleicon: require("./googleicon.svg"),
  gulfP500: require("./gulf-p-500.png"),
  gulfP800: require("./gulf-p-800.png"),
  gulf: require("./gulf.png"),
  handicapP1080: require("./handicap-p-1080.png"),
  handicapP500: require("./handicap-p-500.png"),
  handicapP800: require("./handicap-p-800.png"),
  handicap: require("./handicap.png"),
  herohorseimgP1080: require("./herohorseimg-p-1080.png"),
  herohorseimgP1600: require("./herohorseimg-p-1600.png"),
  herohorseimgP2000: require("./herohorseimg-p-2000.png"),
  herohorseimgP500: require("./herohorseimg-p-500.png"),
  herohorseimgP800: require("./herohorseimg-p-800.png"),
  herohorseimg: require("./herohorseimg.png"),
  herotimg: require("./herotimg.svg"),
  horse: require("./horse.svg"),
  horseplayer: require("./horseplayer.svg"),
  icClose: require("./ic-close.svg"),
  index: require("./index.js"),
  instagram: require("./instagram.svg"),
  joehorse: require("./joehorse.svg"),
  kentucky: require("./kentucky.svg"),
  lightning: require("./lightning.svg"),
  locationIcon: require("./location-icon.svg"),
  logo: require("./logo.svg"),
  noteflow: require("./noteflow.svg"),
  nyra: require("./nyra.svg"),
  owner: require("./owner.svg"),
  flagus: require("./flag_us.svg"),
  flagsp: require("./flag_sp.svg"),
  proimg: require("./proimg.jpg"),
  racenoteicon: require("./racenoteicon.svg"),
  rnoteIicon: require("./rnote-icon.svg"),
  santaP500: require("./santa-p-500.png"),
  santaP800: require("./santa-p-800.png"),
  santa: require("./santa.png"),
  tdIicon: require("./td-icon.svg"),
  tnoteIicon: require("./tnote-icon.svg"),
  trackbiasicon: require("./trackbiasicon.svg"),
  trainericon: require("./trainericon.svg"),
  triplogo: require("./triplogo.png"),
  tripnoteicon: require("./tripnoteicon.svg"),
  twitter: require("./twitter.svg"),
  twittericon: require("./twittericon.svg"),
  usericon: require("./usericon.svg"),
  webclip: require("./webclip.png"),
};
export default images;
