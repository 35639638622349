import React, { Component } from 'react';
// import images from '../../assets/images/index';

class AuthLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <>
        {/* <div className="login-body horse">
                <div className="login-wrapper">
                    <div className="login-content">
                        <div className="login-rightbar f-wth">
                            {children}
                        </div>
                    </div>
                </div>
            </div> */}

        <div className='login-body horse'>
          <div className='login-wrapper'>
            <div className='login-content'>
              <div className='login-rightbar f-wth'>{children}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AuthLayout;
