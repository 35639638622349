import * as actions from "./types";

/**
 * @desc Set success message
 */
export const setSuccessMessage = (payload) => {
    return {
        type: actions.SET_RES_SUCCESS_MSG,
        payload: payload
    };
};

/**
 * @desc Set Error message
 */
export const setErrorMessage = (payload) => {
    return {
        type: actions.SET_RES_ERROR_MSG,
        payload: payload
    };
};

/**
 * @desc Clear Response message
 */
export const clearResponseMessage = () => {
    return {
        type: actions.CLEAR_RES_MSG,
    };
};