import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images';
import { logout, updateUserProfile, getUserProfile } from '../../services/authService';
import { getRaceData } from '../../services/dashboardService';
import Loading from '../../components/Loading/Loading';
import classNames from 'classnames';
import { setSelectedRace, setSelectedRaceHorses } from '../../actions/dashboardActions';
import { initWebflowReady, isStirng } from '../../global/Helpers';
import HourseDetails from './HourseDetails';
import HourseItem from './HourseItem';
import { trackActivity } from '../../services/analyticsService';
import { DropDown } from '../../components/DropDown';
import languages from '../../data/languages';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTrack: '',
      trackRaces: [],
      trackRaceDates: [],
      trackDatesRaces: [],
      raceTrips: [],
      selectedHorseTripDetails: undefined,
      selectedHorseIndex: -1,
      prevHorse: undefined,
      nextHorse: undefined
    };
  }

  componentDidMount() {
    trackActivity('navigation: dashboard');
    this.LoadData();
  }

  LoadData = async () => {
    await this.LoadProfileData();
    const raceData = await this.props.getRaceData();
    if (raceData) {
      let track;
      if (raceData && raceData.tracks && raceData.tracks.length === 0) {
        this.props.history.push('/pricing');
        return;
      } else {
        track = raceData.tracks[0];
      }
      this.setRaceList(track);
    }
    initWebflowReady();
  };

  LoadProfileData = async () => {
    await this.props.getUserProfile();
  };

  setRaceList = (track) => {
    const { raceData } = this.props.dashboard;
    if (!track) return;
    let trackRaces = [];
    let trackRaceDates = [];

    if (raceData) {
      // filter selected track and purchased races
      if (raceData.race) trackRaces = raceData.race.filter((x) => x.track === track && x.purchased === true);
      if (trackRaces && trackRaces.length > 0) {
        // track races group by date
        trackRaces.map((x) => {
          trackRaceDates.push(x.formated_date);
        });
        // get unique dates only
        trackRaceDates = trackRaceDates.filter((value, index, self) => self.indexOf(value) === index);
        // sort dates in desc order
        trackRaceDates = trackRaceDates.sort((a, b) => {
          const date1 = new Date(a);
          const date2 = new Date(b);
          if (date1 > date2) return -1;
          if (date2 > date1) return 1;

          return 0;
        });
      }
    }

    this.setState({ selectedTrack: track, trackRaces, trackRaceDates }, () => {
      this.trackAnalyticsActions(1);
      // set selected track date
      if (trackRaceDates && trackRaceDates.length > 0) this.setRaceDateList(trackRaceDates[0]);
    });
  };

  setRaceDateList = (date) => {
    const { trackRaces } = this.state;
    if (!date) return;
    let trackDatesRaces = [];

    // filter selected track races based on selected date
    if (trackRaces && trackRaces.length > 0) {
      trackDatesRaces = trackRaces.filter((x) => x.formated_date === date);
    }

    this.setState({ selectedRaceDate: date, trackDatesRaces }, () => {
      // set selected track, selected date race/horses
      if (trackDatesRaces && trackDatesRaces.length > 0) this.setRaceHorseList(trackDatesRaces[0].id);
    });
  };

  setRaceHorseList = (raceId) => {
    if (!raceId) raceId = 'race_1';

    const { raceData } = this.props.dashboard;
    const { trackDatesRaces } = this.state;
    const race = trackDatesRaces.find((x) => x.id === raceId);
    this.props.setSelectedRace(race);
    if (race && !race.purchased) {
      this.props.history.push('/pricing');
      return;
    }
    if (raceData && raceData.trips) {
      const raceTrips = raceData.trips
        .filter((x) => x.race === raceId)
        .sort((a, b) => {
          return Number(a.number) > Number(b.number);
        });
      this.setState({ raceTrips }, () => {
        if (raceTrips && raceTrips.length > 0) this.setTripDetails(raceTrips[0].id);
        else {
          this.setTripDetails(undefined);
        }
      });
    }
    this.trackAnalyticsActions(2, race);
  };

  setTripDetails = (tripId) => {
    let selectedHorseTripDetails;

    const { raceData } = this.props.dashboard;
    const { raceTrips } = this.state;
    const tripIndex = raceTrips.findIndex((x) => x.id === tripId);

    if (tripIndex !== -1) {
      const trip = raceTrips[tripIndex];

      const horseInfo = raceData.horse.find((x) => x.name === trip.horse);
      const saddle = raceData.saddle.find((x) => Number(x.number) === Number(trip.number)) || {};

      let surfaceType;
      let summaryTokensList;
      if (horseInfo.last_race_surface) {
        const last_race_surface = horseInfo.last_race_surface.split(',');
        last_race_surface.forEach((item) => {
          surfaceType = item;
        });
      }
      if (trip.summary) {
        summaryTokensList = trip.summary.split(',');
      }
      selectedHorseTripDetails = {
        trip: trip,
        horseInfo: horseInfo,
        saddle: saddle,
        surfaceType: surfaceType,
        summaryTokens: summaryTokensList
      };
    }
    let hasPrevHourse = tripIndex > 0;
    let hasNextHourse = tripIndex < raceTrips.length - 1;
    this.setState(
      {
        selectedHorseTripDetails,
        hasNextHourse,
        hasPrevHourse,
        hourseIndex: tripIndex
      },
      () => {
        this.trackAnalyticsActions(3);
      }
    );
  };

  getSurfaceColor = (type) => {
    if (!type) return;
    const name = type.toLowerCase().trim();
    if (name === 'dirt') return '';
    else if (name === 'turf') return 'green';
    else if (name === 'synth') return 'blue';
  };

  onPrevNextHorse = (type) => {
    const { hasPrevHourse, hasNextHourse, hourseIndex, raceTrips = [] } = this.state;
    if (type === 'prev') {
      if (hasPrevHourse) {
        const hourse = raceTrips[hourseIndex - 1];
        this.setTripDetails(hourse.id);
      }
    } else if (type === 'next') {
      if (hasNextHourse) {
        const hourse = raceTrips[hourseIndex + 1];
        this.setTripDetails(hourse.id);
      }
    }
  };

  onPurchaseClick = () => {
    this.props.history.push('/pricing');
  };

  onTransactionsClick = () => {
    this.props.history.push('/transactions');
  };

  onPaymentTypesClick = () => {
    this.props.history.push('/manage-payments');
  };

  onProfileClick = () => {
    this.props.history.push('/profile');
  };

  onGlossaryClick = () => {
    this.props.history.push('/glossary');
  };

  onLogOut = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  onLanguageChange = async (language) => {
    if (!language || !isStirng(language)) return;
    const result = await this.props.updateUserProfile({ language });
    if (result) {
      this.LoadData();
    }
  };

  trackAnalyticsActions = (type, race = {}) => {
    if (!type) return;
    const { user } = this.props.auth;
    const { selectedRace } = this.props.dashboard;
    if (race && !race.name) race = selectedRace;
    const { selectedTrack, selectedHorseTripDetails } = this.state;
    let postfix,
      properties = { user: user.displayName };
    if (type === 1) {
      postfix = 'track selected';
      properties = { ...properties, track: selectedTrack.toLowerCase() };
    } else if (type === 2) {
      postfix = 'race selected';
      properties = { ...properties, track: selectedTrack.toLowerCase(), race: race.name };
    } else if (type === 3 && selectedHorseTripDetails && selectedHorseTripDetails.horseInfo) {
      postfix = 'tip viewed';
      properties = {
        ...properties,
        track: selectedTrack.toLowerCase(),
        race: race.name,
        horse: selectedHorseTripDetails.horseInfo.name
      };
    }
    trackActivity('dashboard: ' + postfix, properties);
  };

  render() {
    const { user, userProfile } = this.props.auth;
    const { raceData, selectedRace } = this.props.dashboard;
    const {
      trackRaces,
      raceTrips,
      selectedHorseTripDetails,
      selectedTrack,
      hasNextHourse,
      hasPrevHourse,
      trackDatesRaces,
      trackRaceDates,
      selectedRaceDate
    } = this.state;
    const loading_auth = this.props.auth.loading;
    const loading_dashboard = this.props.dashboard.loading;
    const loading = loading_auth || loading_dashboard;
    if (loading || !raceData) return <Loading />;

    const currentLanguage = userProfile ? userProfile.language : 'en';
    return (
      <div className='dashboard-body'>
        <div className='main-wrapper'>
          <div className='header-block'>
            <div className='navbar-dashboard w-nav'>
              <div className='nav-inner'>
                <a className='brand-logo w-nav-brand'>
                  <img src={images['triplogo']} alt='' />
                </a>
                {/* <nav className='nav-menu w-nav-menu'>
                  <div className='menu-inner'>
                    {raceData.tracks &&
                      raceData.tracks.length > 0 &&
                      raceData.tracks.map((item) => (
                        <a
                          key={String(item)}
                          className={classNames('nav-link w-nav-link', {
                            'w--current': selectedTrack === item
                          })}
                          onClick={() => {
                            this.setRaceList(item);
                          }}>
                          {item}
                        </a>
                      ))}
                  </div>
                </nav> */}
                <div className='dropdown-list-wrap'>
                  <div className='w-dropdown'>
                    <div className='dropdown-toggle w-dropdown-toggle'>
                      <span>{selectedTrack}</span>
                      <div className='profile-arrow w-icon-dropdown-toggle'></div>
                    </div>

                    <nav className='dropdown-list w-dropdown-list'>
                      {raceData.tracks &&
                        raceData.tracks.length > 0 &&
                        raceData.tracks.map((item) => (
                          <a
                            key={String(item)}
                            className={classNames('nav-link w-nav-link', {
                              'w--current': selectedTrack === item
                            })}
                            onClick={() => {
                              this.setRaceList(item);
                            }}>
                            {item}
                          </a>
                        ))}
                    </nav>
                  </div>

                  <div className='dropdown-list-point'></div>

                  <div className='w-dropdown'>
                    <div className='dropdown-toggle w-dropdown-toggle'>
                      <span>{selectedRace.formated_date}</span>
                      <div className='profile-arrow w-icon-dropdown-toggle'></div>
                    </div>

                    <nav className='dropdown-list w-dropdown-list'>
                      {trackRaceDates &&
                        trackRaceDates.length > 0 &&
                        trackRaceDates.map((item) => (
                          <a
                            key={String(item)}
                            className={classNames('nav-link w-nav-link', {
                              'w--current': selectedRaceDate === item
                            })}
                            onClick={() => {
                              this.setRaceDateList(item);
                            }}>
                            {item}
                          </a>
                        ))}
                    </nav>
                  </div>
                </div>
                <div className='nav-top-menubar'>
                  <button type='button' className='nav-link w-nav-link ctm' onClick={this.onPurchaseClick}>
                    Buy Notes
                  </button>
                </div>

                <div className='profile-block'>
                  <div className='w-dropdown'>
                    <div className='dropdown-toggle w-dropdown-toggle'>
                      {user && user.photoURL && (
                        <div className='profile-img'>
                          <img src={user.photoURL} width='36' height='36' alt='' />
                        </div>
                      )}
                      <div className='profile-arrow w-icon-dropdown-toggle'></div>
                      <div className='profile-name'>{user.displayName}</div>
                    </div>
                    <nav className='dropdown-list w-dropdown-list'>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onTransactionsClick}>
                        Transactions
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onPaymentTypesClick}>
                        Manage Payments
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onProfileClick}>
                        Profile
                      </a>
                      <a
                        className='dropdown-link w-dropdown-link'
                        href='https://www.tripnotepros.com/glossary'
                        target='_blank'>
                        Glossary
                      </a>
                      <a className='dropdown-link w-dropdown-link' href='mailto:benny@tripnotepros.com' target='_blank'>
                        Contact Us
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onLogOut}>
                        Logout
                      </a>
                    </nav>
                  </div>
                </div>

                {currentLanguage &&
                  languages &&
                  languages.length > 0 &&
                  languages.map((item) => {
                    if (currentLanguage !== item.id)
                      return (
                        <div
                          className='language-block'
                          key={item.id}
                          onClick={() => {
                            this.onLanguageChange(item.id);
                          }}>
                          <a>{item.value}</a>
                        </div>
                      );
                    else return null;
                  })}

                {/* <DropDown
                  selectedValue={userProfile ? userProfile.language : 'en'}
                  options={languages}
                  onChange={this.onLanguageChange}
                /> */}

                {/* <div className='menu-button w-nav-button'>
                  <div className='icon w-icon-nav-menu'></div>
                  <div className='top-line'></div>
                  <div className='middle-line'></div>
                  <div className='bottom-line'></div>
                </div>
                <div className='profile-block'>
                  <div className='w-dropdown'>
                    <div className='dropdown-toggle w-dropdown-toggle'>
                      {user && user.photoURL && (
                        <div className='profile-img'>
                          <img src={user.photoURL} width='36' height='36' alt='' />
                        </div>
                      )}
                      <div className='profile-arrow w-icon-dropdown-toggle'></div>
                      <div className='profile-name'>{user.displayName}</div>
                    </div>
                    <nav className='dropdown-list w-dropdown-list'>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onPurchaseClick}>
                        Purchase
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onTransactionsClick}>
                        Transactions
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onPaymentTypesClick}>
                        Manage Payments
                      </a>
                      <a className='dropdown-link w-dropdown-link' href='mailto:benny@tripnotepros.com' target="_blank">
                        Contact Us
                      </a>
                      <a className='dropdown-link w-dropdown-link' onClick={this.onLogOut}>
                        Logout
                      </a>
                    </nav>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className='content-wrapper'>
            <div className='content-body'>
              <div className='content-leftside'>
                <div className='race-tab-wrapper'>
                  {trackDatesRaces &&
                    trackDatesRaces.length > 0 &&
                    trackDatesRaces.map((item) => (
                      <div
                        key={String(item.id)}
                        className={classNames('racing-tab', {
                          current: selectedRace.id === item.id
                        })}
                        onClick={() => {
                          this.setRaceHorseList(item.id);
                        }}>
                        {item.name}
                      </div>
                    ))}
                </div>
                <div className='race-content'>
                  <div className='race-box-wp'>
                    {raceTrips &&
                      raceTrips.length > 0 &&
                      raceTrips.map((item, index) => {
                        const horseInfo = raceData.horse.find((x) => x.name === item.horse);
                        const saddle = raceData.saddle.find((x) => Number(x.number) === item.number) || {};
                        return (
                          <HourseItem
                            key={String(item.id)}
                            item={item}
                            saddle={saddle}
                            horseInfo={horseInfo}
                            selectedHorseTripDetails={selectedHorseTripDetails}
                            onSetTripDetails={() => {
                              this.setTripDetails(item.id);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              <HourseDetails
                horseTripDetails={selectedHorseTripDetails}
                hasPrev={hasPrevHourse}
                hasNext={hasNextHourse}
                onNext={() => {
                  this.onPrevNextHorse('next');
                }}
                onPrev={() => {
                  this.onPrevNextHorse('prev');
                }}
                onCloseHorseDetails={() => {
                  this.setTripDetails(undefined);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  dashboard: state.dashboard
});

export default connect(mapStateToProps, {
  logout,
  getRaceData,
  setSelectedRace,
  setSelectedRaceHorses,
  getUserProfile,
  updateUserProfile
})(Dashboard);
