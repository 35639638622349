import axios from 'axios';
import { clearResponseMessage, setErrorMessage } from '../actions/messageActions';
import { setDashboardLoader, setRaceData } from '../actions/sampleDashboardActions';
import { REACT_APP_APIURL } from '../global/Environment';
import { getAPIResponseError } from '../global/Helpers';

/**
 * @desc get race data
 */
export const getSampleRaceData = () => async (dispatch) => {
  try {
    dispatch(clearResponseMessage());
    dispatch(setDashboardLoader(true));

    const response = await axios.get(`${REACT_APP_APIURL}/race/sample`);
    const { data } = response.data;
    dispatch(setRaceData(data));
    return data;
  } catch (e) {
    dispatchError(getAPIResponseError(e, dispatch) || 'Unable to fetch race data', dispatch);
    return false;
  } finally {
    dispatch(setDashboardLoader(false));
  }
};

function dispatchError(msg, dispatch) {
  dispatch(setErrorMessage(msg));
}
