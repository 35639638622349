import React, { Component } from 'react';
import classNames from 'classnames';
import images from '../../assets/images';
export default class HourseDetails extends Component {
  getSurfaceColor = (type) => {
    if (!type) return;
    const name = type.toLowerCase().trim();
    if (name === 'dirt') return '';
    else if (name === 'turf') return 'green';
    else if (name === 'synth') return 'blue';
  };

  render() {
    const { horseTripDetails, hasPrev, hasNext, onNext, onPrev, onCloseHorseDetails } = this.props;
    if (!horseTripDetails) return null;
    return (
      <div className='content-rightbar'>
        <div
          className='rightbar-head-block'
          style={{
            backgroundColor: horseTripDetails.saddle.color,
            color: horseTripDetails.saddle.color_2
          }}>
          <h3 className='rightbar-head-text'>
            {horseTripDetails.trip.number} {horseTripDetails.horseInfo.name}
          </h3>
          <div className='close-btn' onClick={onCloseHorseDetails}>
            <img src={images['closebk']} alt='' />
          </div>
        </div>
        <div className='rightbar-content'>
          <div className='trip-info-block'>
            <div className='trip-title-block'>
              <div className='border-blk'></div>
              <h4 className='trip-title'>TRIP DATE</h4>
              <div className='date-text'>
                <span className='date-text sm'>
                  {horseTripDetails.horseInfo.formated_last_race || horseTripDetails.horseInfo.last_race}
                </span>
              </div>
            </div>
            <div className='distance-wp'>
              <div className='dis-surface-block left'>
                <h4 className='trip-sub-title'>Distance</h4>
                <div className='date-text'>
                  <span className='trip-para'>{horseTripDetails.horseInfo.last_race_distance || 'N/A'}</span>
                </div>
              </div>
              <div className='dis-surface-block'>
                <h4 className='trip-sub-title'>Surface</h4>
                <div className='surface-type-block'>
                  {horseTripDetails.surfaceType ? (
                    <div
                      className={classNames('surface-type', `${this.getSurfaceColor(horseTripDetails.surfaceType)}`)}>
                      {horseTripDetails.surfaceType}
                    </div>
                  ) : (
                      <p className='date-text trip-para'>{'N/A'}</p>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className='trip-info-block'>
            <div className='trip-title-block'>
              <div className='border-blk'></div>
              <h4 className='trip-title'>TRIP NOTE</h4>
            </div>
            <p className='trip-para'>{horseTripDetails.trip.trip_note || 'N/A'}</p>
          </div>
          <div className='trip-info-block'>
            <div className='trip-title-block'>
              <div className='border-blk'></div>
              <h4 className='trip-title'>RACE NOTE</h4>
            </div>
            <p className='trip-para'>{horseTripDetails.trip.race_note || 'N/A'}</p>
          </div>
          <div className='trip-info-block'>
            <div className='trip-title-block'>
              <div className='border-blk'></div>
              <h4 className='trip-title'>TRACK BIAS</h4>
            </div>
            <p className='trip-para'>{horseTripDetails.trip.track_bias || 'N/A'}</p>
          </div>
          <div className='trip-info-block'>
            <div className='trip-title-block'>
              <div className='border-blk'></div>
              <h4 className='trip-title'>Summary</h4>
            </div>
            <div className='summary-type-wp'>
              {horseTripDetails.summaryTokens ? (
                <>
                  {horseTripDetails.summaryTokens.length > 0 && horseTripDetails.summaryTokens.map((item, i) => (
                    <div className='summary-block' key={i}>{item}</div>
                  ))}
                </>
              ) : (
                  <p className='trip-para'>{'N/A'}</p>
                )}
            </div>
          </div>
        </div>
        <div className='rightbar-button'>
          <button type='button' disabled={!hasPrev} className='btn primary' onClick={onPrev}>
            previous
          </button>
          <button type='button' disabled={!hasNext} className='btn primary' onClick={onNext}>
            Next
          </button>
        </div>
      </div>
    );
  }
}
