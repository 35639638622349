//Message
export const SET_RES_SUCCESS_MSG = 'SET_RES_SUCCESS_MSG';
export const SET_RES_ERROR_MSG = 'SET_RES_ERROR_MSG';
export const CLEAR_RES_MSG = 'CLEAR_RES_MSG';

//Auth
export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';

//onboarding
export const SET_ONBOARDING_LOADER = 'SET_ONBOARDING_LOADER';
export const SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA';

//Dashboard
export const SET_DASHBOARD_LOADER = 'SET_DASHBOARD_LOADER';
export const SET_RACE_DATA = 'SET_RACE_DATA';
export const SET_SELECTED_RACE = 'SET_SELECTED_RACE';
export const SET_SELECTED_RACE_HORSES = 'SET_SELECTED_RACE_HORSES';

//Sample Dashboard
export const SET_SAMPLE_DASHBOARD_LOADER = 'SET_SAMPLE_DASHBOARD_LOADER';
export const SET_SAMPLE_RACE_DATA = 'SET_SAMPLE_RACE_DATA';
export const SET_SAMPLE_SELECTED_RACE = 'SET_SAMPLE_SELECTED_RACE';
export const SET_SAMPLE_SELECTED_RACE_HORSES = 'SET_SAMPLE_SELECTED_RACE_HORSES';

//Pricing
export const SET_PRICING_LOADER = 'SET_PRICING_LOADER';
export const SET_PRICING_TRACK = 'SET_PRICING_TRACK';
export const SET_SELECTED_BUY_TRACKS = 'SET_SELECTED_BUY_TRACKS';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const SET_TRANSACTIONS_LIST = 'SET_TRANSACTIONS_LIST';
export const SET_PAYMENT_TYPES_LIST = 'SET_PAYMENT_TYPES_LIST';
