import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AuthLayout from "./AuthLayout";
import images from "../../assets/images/index";
import classNames from "classnames";
import { forgotPassword } from "../../services/authService";
import { clearResponseMessage } from "../../actions/messageActions";
import { trackActivity } from "../../services/analyticsService";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  componentDidMount() {
    trackActivity('navigation: forgot password');
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onEmailInputChange = (e) => {
    const email = e.target.value;
    this.setState({ email });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const obj = {
      email: String(email).trim(),
    };
    await this.props.forgotPassword(obj);
  };

  onClickLogin = () => {
    this.props.history.push("/login");
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className="alert-message">{resError}</div>;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return <div className="success-message">{resSuccess}</div>;
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { email } = this.state;
    return (
      <AuthLayout>
        <div className="rightbar-inner">
          <div className="login-rightbar-head">
            <a className="w-inline-block">
              <img src={images["triplogo"]} alt="" />
            </a>
          </div>
          <div className="login-rightbar-content">
            <div className="signup-wrapper">
              <h3 className="signup-text">Forgot Password</h3>
              <div className="form-block">
                <form name="wf-form-Forgot-Form" onSubmit={this.onSubmit}>
                  <div className="form-row">
                    <div className="form-col">
                      <label htmlFor="Email-9" className="form-lable">
                        Email
                      </label>
                      <div className="input-block">
                        <input
                          type="email"
                          className="form-input w-input"
                          maxLength="256"
                          name="Email"
                          placeholder="venkat@tradeanalyst.com"
                          required=""
                          value={email}
                          onChange={this.onEmailInputChange}
                        />
                        <div className="user-icon"></div>
                      </div>
                    </div>
                  </div>
                  {this.renderErrors()}
                  {this.renderSuccess()}
                  <div
                    className={classNames('form-btn-block', {
                      'mc-loading': loading === true
                    })}>
                    <button
                      type="submit"
                      data-wait="Please wait..."
                      disabled={loading}
                      className="btn-square w-button"
                    >
                      Forgot Password
                      </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="login-rightbar-bottom">
              <p className="rightbar-btm-text">
                Go back to{" "}
                <a
                  className="btm-link"
                  disabled={loading}
                  onClick={this.onClickLogin}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
});

export default connect(mapStateToProps, {
  forgotPassword,
  clearResponseMessage,
})(ForgotPassword);
