import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setErrorMessage, clearResponseMessage } from '../../actions/messageActions';
import { trackActivity } from '../../services/analyticsService';
import languages from '../../data/languages';
import images from '../../assets/images';

class OnboardingStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: undefined
    };
  }

  componentDidMount() {
    trackActivity('navigation: onboarding step3');
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onChange = (isChecked, item) => {
    if (!item) return;
    if (isChecked) this.setState({ selectedLanguage: item });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { selectedLanguage } = this.state;
    if (!selectedLanguage) {
      this.props.setErrorMessage('Atleast one selection is required');
      return;
    }

    if (this.props.onGetStarted) this.props.onGetStarted(selectedLanguage.id);
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    const { selectedLanguage } = this.state;
    return (
      <div className='onboarding-content'>
        <div className='onboarding-text-block'>
          <h1 className='onboarding-heading'>
            Hi <span>{user.displayName}</span>
          </h1>
        </div>
        <div className='onboarding-selection'>
        <h3 class="signup-text sm">Which language would you prefer?</h3>
          <div className='form-block'>
            <form onSubmit={this.onSubmit} name='wf-form-Onboarding-Form' data-name='Onboarding Form'>
              <div className='w-embed'>
                <div className='checkbox-wp'>
                  {languages &&
                    languages.length > 0 &&
                    languages.map((item) => {
                      const isChecked = selectedLanguage ? selectedLanguage.id === item.id : false;
                      return (
                        <div className='checkbox-col' key={item.id}>
                          <label className='checkbox-block'>
                            <div className='checkbox-img'>
                              <img src={images[item.img]} alt={item.img} />
                            </div>
                            {item.value}
                            <input
                              type='radio'
                              name='type'
                              value={item.value}
                              checked={isChecked}
                              onChange={(e) => {
                                this.onChange(e.target.checked, item);
                              }}
                            />
                            <span className='checkbox-span'></span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.renderErrors()}
              <div className='form-btn-block mt20'>
                <button type='submit' className='btn-square w-button'>
                  Get Started
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

OnboardingStep1.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setErrorMessage,
  clearResponseMessage
})(OnboardingStep1);
