import axios from 'axios';

// header methods
export const setAuthToken = (token) => {
  try {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
  } catch (e) {
    console.log('Error while settup token', e);
  }
};

export const clearAuthToken = () => {
  delete axios.defaults.headers.common.Authorization;
};
