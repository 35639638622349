import * as actions from "../../actions/types";
import { getFormattedDate } from "../../global/Helpers";

const initialState = {
    pricingTrack: undefined,
    todayDate: undefined,
    selectedBuyTracks: [],
    selectedPlan: {},
    transactionList: undefined,
    paymentTypeList: undefined,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_PRICING_LOADER:
            return {
                ...state,
                loading: action.payload
            };
        case actions.SET_PRICING_TRACK:
            return {
                ...state,
                pricingTrack: action.payload,
                todayDate: getFormattedDate(new Date())
            };
        case actions.SET_SELECTED_BUY_TRACKS:
            return {
                ...state,
                selectedBuyTracks: action.payload
            };
        case actions.SET_SELECTED_PLAN:
            return {
                ...state,
                selectedPlan: action.payload
            };
        case actions.SET_TRANSACTIONS_LIST:
            return {
                ...state,
                transactionList: action.payload
            };
        case actions.SET_PAYMENT_TYPES_LIST:
            return {
                ...state,
                paymentTypeList: action.payload
            };
        default:
            return state;
    }
}
