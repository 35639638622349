import Login from './pages/Membership/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Signup from './pages/Membership/Signup';
import ForgotPassword from './pages/Membership/ForgotPassword';
import ResetPassword from './pages/Membership/ResetPassword';
import Pricing from './pages/Pricing/Pricing';
import PricingTrack from './pages/Pricing/PricingTrack';
import Onboarding from './pages/Onboarding/Onboarding';
import SampleDashboard from './pages/Dashboard/SampleDashboard';
import Transactions from './pages/Transactions/Transactions';
import PaymentTypes from './pages/PaymentTypes/PaymentTypes';
import Profile from './pages/Profile/Profile';

/**
 * Routes Array
 * Same properties should match for all attributes
 */
const routes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
    private: true
  },
  {
    path: '/demo',
    exact: true,
    component: SampleDashboard,
    private: false
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    path: '/signup',
    exact: true,
    component: Signup,
    private: false
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    private: false
  },
  {
    path: '/auth-action',
    exact: true,
    component: ResetPassword,
    private: false
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    private: true
  },
  {
    path: '/pricing',
    exact: true,
    component: Pricing,
    private: false
  },
  {
    path: '/pricing-track',
    exact: true,
    component: PricingTrack,
    private: true
  },
  {
    path: '/onboarding',
    exact: true,
    component: Onboarding,
    private: false
  },
  {
    path: '/transactions',
    exact: true,
    component: Transactions,
    private: true
  },
  {
    path: '/manage-payments',
    exact: true,
    component: PaymentTypes,
    private: true
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    private: true
  }
];

export default routes;
