import { isDev } from '../global/Helpers';
import { REACT_APP_SEGMENT_KEY } from '../global/Environment';

/**
 * @desc init analytics
 */
export const initAnalytics = () => {
  try {
    if (isDev()) {
      console.log('initAnalytics');
      return;
    }
    window.analytics.load(`${REACT_APP_SEGMENT_KEY}`);
  } catch (e) {
    console.error(e);
  }
};

/**
 * @desc set userid and email for analytics
 */
export const setUser = (user) => {
  try {
    if (isDev()) {
      console.log('setAnalyticUser', user.email, user);
      return;
    }
    if (user) {
      window.analytics.identify(user.email, user);
    } else {
      clearUser();
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc set user data when signup
 */
export const setNewUser = (user) => {
  try {
    if (isDev()) {
      console.log('new user', user);
      return;
    }
    if (user) {
      window.analytics.identify(user.email, user);
    }
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc clear user information for analytics
 */
export const clearUser = () => {
  try {
    if (isDev()) {
      console.log('clearUser');
      return;
    }
    window.analytics.reset();
  } catch (e) {
    console.log(e);
  }
};

/**
 * @desc track activity in analytics
 */
export const trackActivity = (name, params) => {
  try {
    if (isDev()) {
      console.log('trackActivity', name, params);
      return;
    }
    window.analytics.track(name, params);
  } catch (e) {
    console.error(e);
  }
};
