import * as actions from "./types";

/**
 * @desc Set onboarding loader
 */
export const setOnboardingLoader = (payload) => {
    return {
        type: actions.SET_ONBOARDING_LOADER,
        payload: payload
    };
};

/**
 * @desc Set onboarding data
 */
export const setOnboardingData = (payload) => {
    return {
        type: actions.SET_ONBOARDING_DATA,
        payload: payload
    };
};