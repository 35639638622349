import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthLayout from './AuthLayout';
// import logosrc from "../../assets/images/triplogo.png";
import classNames from 'classnames';
import { resetPassword, logout } from '../../services/authService';
import { clearResponseMessage } from '../../actions/messageActions';
import images from '../../assets/images';
import { getUrlParam, isEmpty } from '../../global/Helpers';
import { trackActivity } from '../../services/analyticsService';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      isShowPassword: false
    };
  }

  componentDidMount() {
    trackActivity('navigation: reset password');
    const mode = getUrlParam(this.props.location.search, 'mode');
    if (mode !== 'resetPassword') {
      this.navigateToLogin();
      return;
    }
    this.props.clearResponseMessage();
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  handleNewPasswordInputChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword });
  };

  handleConfirmPasswordInputChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState({ confirmPassword });
  };

  toggleIsShowPassword = () => {
    let isShowPassword = this.state.isShowPassword;
    isShowPassword = !isShowPassword;
    this.setState({ isShowPassword });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const obj = {
      password: newPassword,
      confirmPassword: confirmPassword,
      token: getUrlParam(this.props.location.search, 'oobCode')
    };
    const result = await this.props.resetPassword(obj);
    if (result === true) {
      this.props.logout();
    }
  };

  onClickLogin = () => {
    this.navigateToLogin();
  };

  navigateToLogin = () => {
    this.props.history.push('/login');
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  renderSuccess() {
    const { resSuccess } = this.props.message;
    if (resSuccess) {
      return (
        <div className='success-message' style={{ marginTop: 50, marginBottom: 50 }}>
          {resSuccess}
        </div>
      );
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { newPassword, confirmPassword, isShowPassword } = this.state;
    const { resSuccess } = this.props.message;
    return (
      <AuthLayout>
        <div className='rightbar-inner'>
          <div className='login-rightbar-head'>
            <a className='w-inline-block'>
              <img src={images['triplogo']} alt='' />
            </a>
          </div>
          <div className='login-rightbar-content regi'>
            {isEmpty(resSuccess) && (
              <div className='register-wrapper'>
                <h3 className='signup-text'>Reset Password</h3>
                <div className='form-block'>
                  <form name='wf-form-Reset-Form' data-name='Reset Form' onSubmit={this.onSubmit}>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='name-3' className='form-lable'>
                          New Password
                        </label>
                        <div className='input-block'>
                          <input
                            type={isShowPassword ? 'text' : 'password'}
                            className='form-input w-input'
                            maxLength='256'
                            name='Password'
                            data-name='Password'
                            value={newPassword}
                            onChange={this.handleNewPasswordInputChange}
                          />
                          <div
                            className={classNames('eye-icon', {
                              off: isShowPassword
                            })}
                            onClick={this.toggleIsShowPassword}></div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col'>
                        <label htmlFor='name-3' className='form-lable'>
                          Confirm Password
                        </label>
                        <div className='input-block'>
                          <input
                            type={isShowPassword ? 'text' : 'password'}
                            className='form-input w-input'
                            maxLength='256'
                            name='Confirm-password'
                            data-name='Confirm password'
                            value={confirmPassword}
                            onChange={this.handleConfirmPasswordInputChange}
                          />
                          <div
                            className={classNames('eye-icon', {
                              off: isShowPassword
                            })}
                            onClick={this.toggleIsShowPassword}></div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-col'></div>
                    </div>
                    {this.renderErrors()}
                    <div
                      className={classNames('form-btn-block', {
                        'mc-loading': loading === true
                      })}>
                      <button
                        type='submit'
                        data-wait='Please wait...'
                        className='btn-square w-button'
                        disabled={loading}>
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {this.renderSuccess()}
            <div className='login-rightbar-bottom'>
              <p className='rightbar-btm-text'>
                Go back to{' '}
                <a className='btm-link' disabled={loading} onClick={this.onClickLogin}>
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message
});

export default connect(mapStateToProps, {
  logout,
  resetPassword,
  clearResponseMessage
})(ResetPassword);
