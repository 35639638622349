import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setErrorMessage, clearResponseMessage } from '../../actions/messageActions';
import images from '../../assets/images';
import { trackActivity } from '../../services/analyticsService';

class OnboardingStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHorsePlayer: false,
      isOwner: false,
      isTrainer: false,
      isJockeyAgent: false
    };
  }

  componentDidMount() {
    trackActivity('navigation: onboarding step1');
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onCheckboxChange = (e) => {
    const { checked } = e.target;
    let { isHorsePlayer, isOwner, isTrainer, isJockeyAgent } = this.state;
    const type = e.target.value;
    if (type === 'Horseplayer') {
      isHorsePlayer = checked;
    } else if (type === 'Owner') {
      isOwner = checked;
    } else if (type === 'Trainer') {
      isTrainer = checked;
    } else if (type === 'Jockey Agent') {
      isJockeyAgent = checked;
    }
    this.setState({ isHorsePlayer, isOwner, isTrainer, isJockeyAgent });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const user_type = [];
    const { isHorsePlayer, isOwner, isTrainer, isJockeyAgent } = this.state;
    if (isHorsePlayer === true) user_type.push('Horseplayer');
    if (isOwner === true) user_type.push('Owner');
    if (isTrainer === true) user_type.push('Trainer');
    if (isJockeyAgent === true) user_type.push('Jockey Agent');
    if (!user_type || user_type.length === 0) {
      this.props.setErrorMessage('Atleast one selection is required');
      return;
    }

    if (this.props.onNext) this.props.onNext(user_type);
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { user } = this.props.auth;
    const { isHorsePlayer, isOwner, isTrainer, isJockeyAgent } = this.state;
    return (
      <div className='onboarding-content'>
        <div className='onboarding-text-block'>
          <h1 className='onboarding-heading'>
            Hi <span>{user.displayName}</span>, Which best describes you?
          </h1>
          <p className='onboarding-para d-none'>
            View the race note for a look at pace, how the race was run and how the top betting choices performed
          </p>
        </div>
        <div className='onboarding-selection'>
          <h3 className='signup-text sm'>Are You a …</h3>
          <div className='form-block'>
            <form onSubmit={this.onSubmit} name='wf-form-Onboarding-Form' data-name='Onboarding Form'>
              <div className='w-embed'>
                <div className='checkbox-wp'>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['horseplayer']} alt='horseplayer' />
                      </div>
                      Horseplayer
                      <input
                        type='checkbox'
                        name='type'
                        value='Horseplayer'
                        checked={isHorsePlayer}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['owner']} alt='owner' />
                      </div>
                      Owner
                      <input
                        type='checkbox'
                        name='type'
                        value='Owner'
                        checked={isOwner}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['trainericon']} alt='trainer' />
                      </div>
                      Trainer
                      <input
                        type='checkbox'
                        name='type'
                        value='Trainer'
                        checked={isTrainer}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block lst'>
                      <div className='checkbox-img'>
                        <img src={images['agent']} alt='agent' />
                      </div>
                      Jockey Agent
                      <input
                        type='checkbox'
                        name='type'
                        value='Jockey Agent'
                        checked={isJockeyAgent}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                </div>
              </div>
              {this.renderErrors()}
              <div className='form-btn-block mt20'>
                <button type='submit' className='btn-square w-button'>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

OnboardingStep1.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setErrorMessage,
  clearResponseMessage
})(OnboardingStep1);
