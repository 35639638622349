import * as actions from './types';

/**
 * @desc Set dashboard loader
 */
export const setDashboardLoader = (payload) => {
  return {
    type: actions.SET_SAMPLE_DASHBOARD_LOADER,
    payload: payload
  };
};

/**
 * @desc Set race data
 */
export const setRaceData = (payload) => {
  return {
    type: actions.SET_SAMPLE_RACE_DATA,
    payload: payload
  };
};

/**
 * @desc Set selected race
 */
export const setSelectedRace = (payload) => {
  return {
    type: actions.SET_SAMPLE_SELECTED_RACE,
    payload: payload
  };
};

/**
 * @desc Set selected race horses
 */
export const setSelectedRaceHorses = (payload) => {
  return {
    type: actions.SET_SAMPLE_SELECTED_RACE_HORSES,
    payload: payload
  };
};
