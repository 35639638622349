import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import images from '../../assets/images';
import { logout } from '../../services/authService';
import { getSampleRaceData } from '../../services/sampleDashboardService';
import Loading from '../../components/Loading/Loading';
import classNames from 'classnames';
import { setSelectedRace, setSelectedRaceHorses } from '../../actions/sampleDashboardActions';
import { initWebflowReady } from '../../global/Helpers';
import HourseDetails from './HourseDetails';
import HourseItem from './HourseItem';

class SampleDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTrack: '',
      trackRaces: [],
      raceTrips: [],
      selectedHorseTripDetails: undefined,
      selectedHorseIndex: -1,
      prevHorse: undefined,
      nextHorse: undefined
    };
  }

  componentDidMount() {
    this.LoadData();
  }

  LoadData = async () => {
    const raceData = await this.props.getSampleRaceData();
    if (raceData) {
      let track;
      if (raceData && raceData.tracks && raceData.tracks.length === 0) {
        this.props.history.push('/pricing');
        return;
      } else {
        track = raceData.tracks[0];
      }
      this.setRaceList(track);
    }
    initWebflowReady();
  };

  setRaceList = (track) => {
    const { raceData } = this.props.dashboard;
    if (!track) return;
    let trackRaces = [];

    if (raceData && raceData.race) {
      trackRaces = raceData.race.filter((x) => x.track === track && x.purchased === true);
    }
    this.setState({ selectedTrack: track, trackRaces }, () => {
      if (trackRaces && trackRaces.length > 0) this.setRaceHorseList(trackRaces[0].id);
    });
  };

  setRaceHorseList = (raceId) => {
    if (!raceId) raceId = 'race_1';

    const { raceData } = this.props.dashboard;
    const { trackRaces } = this.state;
    const race = trackRaces.find((x) => x.id === raceId);
    this.props.setSelectedRace(race);
    if (race && !race.purchased) {
      this.props.history.push('/pricing');
      return;
    }
    if (raceData && raceData.trips) {
      const raceTrips = raceData.trips
        .filter((x) => x.race === raceId)
        .sort((a, b) => {
          return Number(a.number) > Number(b.number);
        });
      this.setState({ raceTrips }, () => {
        if (raceTrips && raceTrips.length > 0) this.setTripDetails(raceTrips[0].id);
        else {
          this.setTripDetails(undefined);
        }
      });
    }
  };

  setTripDetails = (tripId) => {
    let selectedHorseTripDetails;

    const { raceData } = this.props.dashboard;
    const { raceTrips } = this.state;
    const tripIndex = raceTrips.findIndex((x) => x.id === tripId);

    if (tripIndex !== -1) {
      const trip = raceTrips[tripIndex];

      const horseInfo = raceData.horse.find((x) => x.name === trip.horse);
      const saddle = raceData.saddle.find((x) => Number(x.number) === Number(trip.number)) || {};

      let surfaceType;
      let summaryTokensList;
      if (horseInfo.last_race_surface) {
        const last_race_surface = horseInfo.last_race_surface.split(',');
        last_race_surface.forEach((item) => {
          surfaceType = item;
        });
      }
      if (trip.summary) {
        summaryTokensList = trip.summary.split(',');
      }
      selectedHorseTripDetails = {
        trip: trip,
        horseInfo: horseInfo,
        saddle: saddle,
        surfaceType: surfaceType,
        summaryTokens: summaryTokensList
      };
    }

    let hasPrevHourse = tripIndex > 0;
    let hasNextHourse = tripIndex < raceTrips.length - 1;
    this.setState({
      selectedHorseTripDetails,
      hasNextHourse,
      hasPrevHourse,
      hourseIndex: tripIndex
    });
  };

  getSurfaceColor = (type) => {
    if (!type) return;
    const name = type.toLowerCase().trim();
    if (name === 'dirt') return '';
    else if (name === 'turf') return 'green';
    else if (name === 'synth') return 'blue';
  };

  onPrevNextHorse = (type) => {
    const { hasPrevHourse, hasNextHourse, hourseIndex, raceTrips = [] } = this.state;
    if (type === 'prev') {
      if (hasPrevHourse) {
        const hourse = raceTrips[hourseIndex - 1];
        this.setTripDetails(hourse.id);
      }
    } else if (type === 'next') {
      if (hasNextHourse) {
        const hourse = raceTrips[hourseIndex + 1];
        this.setTripDetails(hourse.id);
      }
    }
  };

  onPurchaseClick = () => {
    this.props.history.push('/pricing');
  };

  onLogOut = () => {
    this.props.logout();
    this.props.history.push('/login');
  };

  render() {
    // const { user } = this.props.auth;
    const { loading, raceData, selectedRace } = this.props.dashboard;
    const { trackRaces, raceTrips, selectedHorseTripDetails, selectedTrack, hasNextHourse, hasPrevHourse } = this.state;

    if (loading || !raceData) return <Loading />;
    return (
      <div className='dashboard-body'>
        <div className='main-wrapper'>
          <div className='header-block'>
            <div className='navbar-dashboard w-nav'>
              <div className='nav-inner'>
                <a className='brand-logo w-nav-brand'>
                  <img src={images['triplogo']} alt='' />
                </a>
                <nav className='nav-menu w-nav-menu'>
                  <div className='menu-inner'>
                    {raceData.tracks &&
                      raceData.tracks.length > 0 &&
                      raceData.tracks.map((item) => (
                        <a
                          key={String(item)}
                          className={classNames('nav-link w-nav-link', {
                            'w--current': selectedTrack === item
                          })}
                          onClick={() => {
                            this.setRaceList(item);
                          }}>
                          {item}
                        </a>
                      ))}
                  </div>
                </nav>
                <div className='menu-button w-nav-button'>
                  <div className='icon w-icon-nav-menu'></div>
                  <div className='top-line'></div>
                  <div className='middle-line'></div>
                  <div className='bottom-line'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='content-wrapper'>
            <div className='content-head'>
              <h2 className='content-head-text'>
                <span>{selectedTrack}</span> <span className='date-text'>{selectedRace.formated_date}</span>
              </h2>
            </div>
            <div className='content-body'>
              <div className='content-leftside'>
                <div className='race-tab-wrapper'>
                  {trackRaces &&
                    trackRaces.length > 0 &&
                    trackRaces.map((item) => (
                      <div
                        key={String(item.id)}
                        className={classNames('racing-tab', {
                          current: selectedRace.id === item.id
                        })}
                        onClick={() => {
                          this.setRaceHorseList(item.id);
                        }}>
                        {item.name}
                      </div>
                    ))}
                </div>
                <div className='race-content'>
                  <div className='race-box-wp'>
                    {raceTrips &&
                      raceTrips.length > 0 &&
                      raceTrips.map((item, index) => {
                        const horseInfo = raceData.horse.find((x) => x.name === item.horse);
                        const saddle = raceData.saddle.find((x) => Number(x.number) === item.number) || {};
                        return (
                          <HourseItem
                            key={String(item.id)}
                            item={item}
                            saddle={saddle}
                            horseInfo={horseInfo}
                            selectedHorseTripDetails={selectedHorseTripDetails}
                            onSetTripDetails={() => {
                              this.setTripDetails(item.id);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              <HourseDetails
                horseTripDetails={selectedHorseTripDetails}
                hasPrev={hasPrevHourse}
                hasNext={hasNextHourse}
                onNext={() => {
                  this.onPrevNextHorse('next');
                }}
                onPrev={() => {
                  this.onPrevNextHorse('prev');
                }}
                onCloseHorseDetails={() => {
                  this.setTripDetails(undefined);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SampleDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
  dashboard: state.sampledashboard
});

export default connect(mapStateToProps, {
  logout,
  getSampleRaceData,
  setSelectedRace,
  setSelectedRaceHorses
})(SampleDashboard);
