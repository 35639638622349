import * as actions from "../../actions/types";

const initialState = {
    loading: false,
    onboardingData: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_ONBOARDING_LOADER:
            return {
                ...state,
                loading: action.payload
            };
        case actions.SET_ONBOARDING_DATA:
            return {
                ...state,
                onboardingData: action.payload
            };
        default:
            return state;
    }
}
