import React, { Component } from "react";
import classNames from "classnames";
import images from "../../assets/images";

export default class HourseItem extends Component {
  render() {
    const {
      selectedHorseTripDetails,
      saddle,
      horseInfo,
      item,
      onSetTripDetails,
    } = this.props;
    return (
      <div className="race-box-col" key={String(item.id)}>
        <div
          className={classNames("race-box", {
            selected:
              selectedHorseTripDetails &&
              String(selectedHorseTripDetails.trip.id) === String(item.id),
          })}
          style={
            selectedHorseTripDetails &&
            selectedHorseTripDetails.trip.id === item.id
              ? { borderColor: saddle.color }
              : {}
          }
        >
          <div
            id="boxborder"
            className="box-border"
            style={{ backgroundColor: saddle.color }}
          ></div>
          <div className="horse-img">
            <img src={images["horse"]} alt="" />
            <div className="horsepad-img w-embed">
              <svg
                viewBox="0 0 44 40"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill={saddle.color}
                className="horsepad-img"
              >
                <title>Path 4</title>
                <g stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
                  <g transform="translate(-50.000000, -37.000000)" fill="">
                    <g transform="translate(0.000000, 6.000000)">
                      <path d="M51.073968,44.4685239 C60.6498902,53.2394252 66.8298007,61.9023117 69.6136996,70.4571833 C78.607474,69.5188297 86.6053049,65.1873865 93.6071923,57.4628536 C87.8050549,45.6819614 78.3604132,36.0134047 65.2732672,28.4571833 C65.1713614,28.6523644 65.1713614,29.2445209 65.2732672,30.2336527 C64.2797334,31.1720836 63.8400373,33.5503243 63.9541791,37.368375 C64.2659414,38.7091721 64.2659414,39.9825775 63.9541791,41.1885912 C60.3977174,42.0296509 57.4238435,42.5452407 55.0325573,42.7353607 C50.8085864,43.0115196 50.9097634,44.0108483 50.6717095,44.0410981 C50.5130068,44.0612646 50.647093,44.2037399 51.073968,44.4685239 Z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="box-number" style={{ color: saddle.color_2 }}>
              {item.number}
            </div>
          </div>
          <h4 className="box-title">{horseInfo.name}</h4>
          <a className="view-button" onClick={onSetTripDetails}>
            View
          </a>
          <div className="box-wave-block">
            <div className="wave-bg"></div>
            <div className="wave-line w-embed">
              <svg
                viewBox="0 0 271 36"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke={saddle.color}
                fill="#121212"
                className="wave-line"
                height="40px"
                width="100%"
              >
                <title>Wave Line</title>
                <g stroke="" strokeWidth="1" fill="" fillRule="evenodd">
                  <g transform="translate(-340.000000, -282.000000)" stroke="">
                    <g transform="translate(340.000000, 220.000000)">
                      <g transform="translate(-5.000000, -5.000000)">
                        <path d="M9,95.3579841 C49.8063791,63.8172447 93.6397124,59.7057292 140.5,83.0234375 C187.360288,106.341146 234.193621,108.727716 281,90.1831472 L281,5.68434189e-14 L0,5.68434189e-14 L0,97.8579841 L9,95.3579841 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
