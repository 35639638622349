import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearResponseMessage, setErrorMessage } from '../../actions/messageActions';
import images from '../../assets/images';
import classNames from 'classnames';
import { trackActivity } from '../../services/analyticsService';

class OnboardingStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNyra: false,
      isKentucky: false,
      isCalifornia: false,
      isFlorida: false
    };
  }

  componentDidMount() {
    trackActivity('navigation: onboarding step2');
  }

  componentWillUnmount() {
    this.props.clearResponseMessage();
  }

  onCheckboxChange = (e) => {
    const { checked } = e.target;
    let { isNyra, isKentucky, isCalifornia, isFlorida } = this.state;
    const type = e.target.value;
    if (type === 'NYRA') {
      isNyra = checked;
    } else if (type === 'Kentucky') {
      isKentucky = checked;
    } else if (type === 'California') {
      isCalifornia = checked;
    } else if (type === 'Florida') {
      isFlorida = checked;
    }
    this.setState({ isNyra, isKentucky, isCalifornia, isFlorida });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const interested_racetracks = [];
    const { isNyra, isKentucky, isCalifornia, isFlorida } = this.state;
    if (isNyra === true) interested_racetracks.push('NYRA');
    if (isKentucky === true) interested_racetracks.push('Kentucky');
    if (isCalifornia === true) interested_racetracks.push('California');
    if (isFlorida === true) interested_racetracks.push('Florida');

    if (!interested_racetracks || interested_racetracks.length === 0) {
      this.props.setErrorMessage('Atleast one selection is required');
      return;
    }

    if (this.props.onNext) this.props.onNext(interested_racetracks);
  };

  renderErrors() {
    const { resError } = this.props.message;
    if (resError) {
      return <div className='alert-message'>{resError}</div>;
    }
  }

  render() {
    const { isNyra, isKentucky, isCalifornia, isFlorida } = this.state;
    const { loading } = this.props.onboarding;
    return (
      <div className='onboarding-content'>
        <div className='onboarding-text-block'>
          <h1 className='onboarding-heading'>Which Racetracks are you interested in?</h1>
          <p className='onboarding-para d-none'>
            View the race note for a look at pace, how the race was run and how the top betting choices perofmred
          </p>
        </div>
        <div className='onboarding-selection'>
          <h3 className='signup-text sm'>Which racetracks are you interested in?</h3>
          <div className='form-block w-form'>
            <form onSubmit={this.onSubmit} name='wf-form-Onboarding-Form' data-name='Onboarding Form'>
              <div className='w-embed'>
                <div className='checkbox-wp'>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['nyra']} alt='nyra' />
                      </div>
                      NYRA
                      <input
                        type='checkbox'
                        name='race_track'
                        value='NYRA'
                        checked={isNyra}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['kentucky']} alt='kentucky' />
                      </div>
                      Kentucky
                      <input
                        type='checkbox'
                        name='race_track'
                        value='Kentucky'
                        checked={isKentucky}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block'>
                      <div className='checkbox-img'>
                        <img src={images['california']} alt='california' />
                      </div>
                      California
                      <input
                        type='checkbox'
                        name='race_track'
                        value='California'
                        checked={isCalifornia}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                  <div className='checkbox-col'>
                    <label className='checkbox-block lst'>
                      <div className='checkbox-img'>
                        <img src={images['florida']} alt='florida' />
                      </div>
                      Florida
                      <input
                        type='checkbox'
                        name='race_track'
                        value='Florida'
                        checked={isFlorida}
                        onChange={this.onCheckboxChange}
                      />
                      <span className='checkbox-span'></span>
                    </label>
                  </div>
                </div>
              </div>
              {this.renderErrors()}
              <div className={classNames('form-btn-block mt20', { 'mc-loading': loading === true })}>
                <button type='submit' className='btn-square w-button' disabled={loading}>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

OnboardingStep2.propTypes = {
  message: PropTypes.object.isRequired,
  onboarding: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  message: state.message,
  onboarding: state.onboarding
});

export default connect(mapStateToProps, {
  setErrorMessage,
  clearResponseMessage
})(OnboardingStep2);
